
import * as noneRuntime$yg2fNAO53n from '/opt/buildhome/repo/fe/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [
    "dartsspace.com",
    "dartsspace.test"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['none']: { provider: noneRuntime$yg2fNAO53n, defaults: {} }
}
        